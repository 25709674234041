

.unbulleted
{
    list-style-type: none;
    padding-left: 0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-start: 0;
}

.unbulleted li
{
    padding-bottom: 0.5em;
}

.alert
{
    padding-left: 2em;
    margin-left: 1em;
}

.loginscreen .alert
{
    list-style-type: none;
}

.orders table
{
    font-size: 11px;
}

.orders table td
{
    position: relative;
}
.sidebar-panel
{
    background: #ebebed;
    padding: 10px 20px;
    right: 0;
}

.sidebar-panel .feed-element img.img-circle
{
    width: 32px;
    height: 32px;
}

.sidebar-panel .feed-element
, .sidebar-panel p {
    font-size: 11px;
}

.sidebar-panel .feed-element
{
    margin-top: 20px;
    padding-bottom: 0;
}

.sidebar-panel .list-group
{
    margin-bottom: 10px;
}

.sidebar-panel .list-group .list-group-item
{
    padding: 5px 0;
    font-size: 12px;
    border: 0;
}

.img-status
{
    position: absolute;
    bottom: 10px;
    left: 6px;
}

.half {
    width:48%;
    margin: 0 1% 1%;
    float: left;
    border-left: 2px solid gray;  
    padding-left: 10px;
}

 .first-col {
    width:20%;
    /*margin: 0 1% 1%;*/
    float: left;
    border-left: 2px solid gray;  
    padding-left: 10px;
}

.second-col {
    width: 40%;
    /*margin: 0 1% 1%;*/
    float: left;
    border-left: 2px solid gray;  
    padding-left: 10px;
}

.third-col {
    width: 40%;
    /*margin: 0 1% 1%;*/
    float: left;
    border-left: 2px solid gray;  
    padding-left: 10px;
}
